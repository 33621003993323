@import "./fonts/fonts.css";
body {
    margin: 0;
    font-family: "Proxima Nova", "Source Sans Pro" -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0 !important;
    overflow: visible !important;
    box-sizing: border-box !important;
}

code {
    font-family: source-code-pro, Menlo, Consolas, "Liberation Mono", Courier, monospace;
}

h2 {
    font-family: "Klavika";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
  }
  
  .title {
    font-family: "Klavika";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
  }
  
  .column_title {
    font-family: "Klavika";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
  }
  
  .text {
    font-family: "Proxima Nova", "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-size: 18px;
  }

  .content {
    margin: 10px 0px 0px 0px;
  }